import request from '@/utils/request'
// 上架下架
//接口：直播间操作-上下架
//地址：http://${BASE_URL}/small-inteface/room/operationStatus?roomId=#{roomId}&status=#{status}
export function operationStatus(data) {
    return request({
        url: `/small-inteface/room/operationStatus?roomId=${data.roomId}&status=${data.status}`,
        method: "post",
        data
    });
}
// 进出轮播
//接口：直播间操作-是否轮播
//地址：http://${BASE_URL}/small-inteface/room/operationBanner?roomId=#{roomId}&isBanner=#{isBanner}
export function operationBanner(data) {
    return request({
        url: `/small-inteface/room/operationBanner?roomId=${data.roomId}&isBanner=${data.isBanner}`,
        method: "post",
        data
    });
}
//获取门店列表
export function getStoreList() {
    return request({
        url: "/goods-service/store/listOfPage",
        method: "get"
    });
}
//数据提交
export function editRoom(data) {
    return request({
        url: `/small-inteface/room/editRoom?roomId=${data.roomId}&storeId=${data.storeId}&roomName=${data.name}`,
        method: "post",
    });
}
export function roomList(data) {
    return request({
        url: `/small-inteface/room/roomList`,
        method: "post",
        data
    });
}